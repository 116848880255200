import { mapGetters, mapActions } from 'vuex'
import { formatDate } from '@/utils'
export default {
  name: 'my-class',
  filters: {
    formatDate
  },
  components: {
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  data() {
    return {
      visibleModal: false,
      selectedClass: {}
    }
  },
  computed: {
    ...mapGetters('prakerja', ['myClass'])
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  created() {
    this.getMyClasses()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('prakerja', ['getMyClass']),
    getMyClasses() {
      this.showLoading()
      this.getMyClass().then(this.success).catch(this.fail)
    },
    success() {
      this.hideLoading()
    },
    fail() {
      this.hideLoading()
    },
    toggleModal(index) {
      this.visibleModal = !this.visibleModal
      this.selectedClass = this.myClass[index]
    },
    getDate(start, end) {
      const formattedStart = this.$options.filters.formatDate(start)
      const formattedEnd = this.$options.filters.formatDate(end)
      if (formattedStart === formattedEnd) {
        return this.$options.filters.formatDate(start)
      } else {
        return this.$options.filters.formatDate(start) + ' - ' + this.$options.filters.formatDate(end)
      }
    }
  }
}
